.page__content {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-color);
  position: relative;
    box-sizing: border-box;
 }
 .content {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 

  
 }
 .page__size {

  width: 100%;
  box-sizing: border-box;
 
 }
 .image__size {
  max-width: 550px;
  width: 100%;
  box-shadow: 1px 1px 5px rgb(72, 68, 68);
 
  }

  @media (max-width: 1140px) {
    .page__size {

      padding-left: 38px;
      padding-right: 38px;
  }
  }


  @media (max-width: 768px) {
    .page__size {

        padding-left: 38px;
        padding-right: 38px;
    }

}

@media (max-width: 570px) {
    .page__size {
        padding-left: 38px;
        padding-right: 38px;
    }

}

@media (max-width: 455px) {

    .page__size {
        padding-left: 15px;
        padding-right: 15px;


    }
  }





 :root{
   --grey-color: #fffff1;
   --blue-color: #a4bfd2
   ;
 
 
 }

 /* --blue-color: #a0d1f4 */