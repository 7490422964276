.page__header {
  max-width: 1180px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;


}
.header__container{
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  padding: 5px 27px 5px 27px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #69649a10;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  box-shadow: 4px 6px 20px rgba(37, 13, 132, 0.15);

}
.header__link-home {
  display: block;
  width: 121px;
  background-position: center;
  height: 50px;
  background-image: url("../../images/lg.png");
  transition: opacity .3s linear;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
  box-shadow: 1px 1px 2px rgb(72, 68, 68);
}
.header__lists {
  display: flex;
  justify-content: space-between;
  list-style: none;
  text-decoration: none;
  gap: 58px;
  
}
.header__link { 
  text-decoration: none;
  font-size: 16px;
  line-height: 35px;
  color: black;
  font-weight: 500;

}
.header__language {
  position: relative;
  width: 50px;
  padding-left: 120px;
}

select {
  border: none; 
  outline: none;
  width: 50px;
  height: 50px; 
}

.header__links {
  transition: transform 0.3s, filter 0.3s; /* Добавляем плавную анимацию для увеличения и изменения цвета */
}

.header__links:hover {
  transform: scale(1.2); /* Увеличиваем размер изображения */
  filter: brightness(110%); /* Подсвечиваем изображение */
  
}
.header__link:hover {
  color: var(--blue-color);
}
.header__button-click {
  background-color: var(--blue-color);
  border: none;
  border-radius: 15px;
  padding: 5px;
  box-shadow: 4px 6px 20px rgba(37, 13, 132, 0.3);
  color: #ffff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, filter 0.3s;
}
.header__button-click:hover {
  transform: scale(1.2); /* Увеличиваем размер изображения */
  filter: brightness(110%); /* Подсвечиваем изображение */
  
}

@media (max-width: 768px) {
  .header__container {
    width: calc(320px + (708 - 320) * ((100vw - 320px)/(768 - 320)));
    padding: 0;
  }

  /* .header__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 33%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9;
  } */

  .header__overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9;
    transform: translateX(100%);
    transition: opacity 3s ease-in-out;
  }


  .header__navigation {
    display: flex;
    justify-content: flex-end;
    position: relative;
    /* width: calc(320px + (670 - 320) * ((100vw - 320px)/(768 - 320))); */
    order: 3;
  }

  .header__lists {
    position: fixed;
    margin: 0;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    line-height: 22px;
    opacity: 0;
    background-color: var(--blue-color);
    text-align: center;
    top: 0;
    left: 67%;
    bottom: 0;
    max-width: 532px;
    width: 100%;
    height: 100vh;
    z-index: 10;
    transform: translateX(50%);
    transition: opacity 2s ease;
    padding: 0;
  }

  .header__link {
    font-size: 18px;
  }
  @keyframes fadeInFromRight {
    0% {
      opacity: 0;
      transform: translateX(50%);
    }
    100% {
      opacity: 1;
      transform: translateX(-50%);
    }
  }
  
  .header__lists_active {
    display: flex;
    animation: fadeInFromRight 0.5s ease forwards;
   
  }

  .header__overlay-active {
    display: ruby;
    background-color: rgba(0, 0, 0, 0.3);
    animation: fadeInFromRight 1s ease forwards;

  }
}


