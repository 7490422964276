
.pictures__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    column-gap: 30px;
    padding-bottom: 25px;
    margin-top: 50px;
}

.pictures__card {
    margin: 0;
    text-align: center;
    color: var(--grey-color)
}
.pictures__img {
    width: 100%;
}
/* @media (max-width: 968px) {
    .pictures__container {

    }
} */
@media (max-width: 768px) {
    .pictures__container {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
        align-items: center;
        justify-content: center;
        justify-items: center;
    }
    .pictures__card {
        width: 95%;
    }
}
@media (max-width: 455px) {
    /* .pictures__card {
        width: 70%;
    } */
    .pictures__container {
        margin-top: 10px;
        row-gap: 5px;
    }
}
