
.contacts__container{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 75px;
    padding-bottom: 75px;
    border-top: 2px solid var(--grey-color);
    gap: 140px;
    font-size: 24px;
}
.contacts__img {
    max-width: 400px;
    height: 500px;
    
}
.contacts__block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.contacts__title {
    color: var(--grey-color);
    margin-top: 0;
}
.contacts__block-tel {
    display: flex;
    flex-direction: column;
}
.contact__links {
    text-decoration: none;
    color: black;
    transition: transform 0.3s, filter 0.3s;

}
.contact__links:hover {
    transform: scale(1.2); /* Увеличиваем размер изображения */
    filter: brightness(110%);
    cursor: pointer;
}
.contacts__link,
.contacts__block-links {
    list-style-type: none; 
    padding: 0;
}
.contacts__map {
    width: 100%;
    
}
.contacts__map-item {
    width: 100%;
    height: 350px;
}
@media (max-width: 968px) {
    .contacts__container {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding-bottom: 20px;
        font-size: 14px;
        padding-top: 25px;
    }
}
@media (max-width: 455px) { 
    .contacts__block {
        width: 70%;
    }
    .contacts__img {
        height: 400px;
    }
}