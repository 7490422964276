.about__container {
    margin-top: 675px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
    box-sizing: border-box;
    padding-top: 30px;
    align-items: center;


}

.about__block {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 23px;
    gap: 30px;
    text-align: left;
    flex-wrap: nowrap;

}

.about__block-title {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.about__text-title {
    margin-bottom: 0;
}

.about__text {
    max-width: 550px;
    width: 100%;
    margin: 0;
    /* padding-top: 20px; */
}

.about__text-pr {
    max-width: 550px;
    width: 100%;
}

.about__text-fat {
    color: var(--grey-color);
    font-weight: 500;
    margin-top: 0;

}

.about__text-text {
    font-weight: 600;
    padding-right: 5px;
}

.about__text-item {
    padding-bottom: 5px;
    width: 100%;
}

.about__title {
    font-size: 24px;
    margin: 0;
    max-width: 300px;
    color: var(--grey-color)
}

.about__block-line {
    padding-top: 25px;
    border-top: 2px solid var(--grey-color);
}

.about__text-pr {
    width: 100%;
}

@media (max-width: 968px) {

    .about__block {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

    }
}


 @media (max-width: 455px) {

    .about__container {

        margin-top: 490px;

    } 

    .about__text-fat {
        padding-left: 10px;
        width: 100%;
        /* font-size: 18px; */
    }

    .about__block {
        font-size: 14px;
    }

     .about__title {
        text-align: center;
        width: 100%;
     }
     .about__block-title {
        justify-content: center;
        align-items: center;
     }
     .about__text-pr {
        margin: 0;
     }
    }