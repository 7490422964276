
.plates__container {
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.plates__obj {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    box-sizing: border-box;
    border-top: 2px solid var(--grey-color);
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 23px;
}

.plates__block-right
 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    box-sizing: border-box;
}
.plates__block-left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px 
}
.plates__block-right {
    justify-content: center;
}
/* .plates__img {
    max-height: 500px;
    height: 100%;
} */
/* .plates__img-plan{
   max-height: 450px; 
} */
.plates__title{
   color: var(--grey-color);
    margin: 0;
    padding-bottom: 20px;
   
}
.plates__subtitle {
    margin: 0;

}
.plates__sub {
    margin: 0;
}
.plates__list {
    margin: 0;
   
}
.plates__list-padding{
    padding-bottom: 10px;
}
@media (max-width: 968px) {
    .plates__obj {
flex-wrap: wrap;
justify-content: center;
align-items: center;
    }
}

@media (max-width: 768px) { 
    .plates__block-right{
        gap: 20px;
    }
    .plates__block-right,
    .plates__block-left {
        width: 100%;
        align-items: center;
        font-size: 14px;
    }
    .plates__list {
        align-self: self-start;
        padding-left: 20px;
      
    }

}