.page__footer {
    max-width: 1280px;
    width: 100%;
    margin-top: 50px;
}
.footer__logo {
    display: block;
    width: 121px;
    background-position: center;
    height: 50px;
    background-image: url("../../images/lg.png");
    transition: opacity .3s linear;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 10px;
    box-shadow: 1px 1px 2px rgb(72, 68, 68);
}
.footer__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 70px 5px 70px;
    box-sizing: border-box;
    /* border-radius: 20px; */
    background-color: #69649a10;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    box-shadow: 1px 1px 10px 10px rgba(21, 5, 88, 0.15);
}
@media (max-width: 550px) { 
    .footer__container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        padding-bottom: 20px;
    }
}