.plateImage {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    gap: 20px;
    column-gap: 30px;
    padding-bottom: 25px;
 
}
@media (max-width: 768px) {
    .plateImage {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
        align-items: center;
        justify-content: center;
        justify-items: center;
    }
}


