.promo__container{
    background-image: url('../../images/promo.png');
    max-width: 1280px;
   width: 100%;
   position: absolute;
   height: 750px;
   background-size: cover;
   background-repeat: no-repeat;
   top: 0px;
   left: 0;
   z-index: 1;
   box-shadow: 1px 1px 5px rgb(72, 68, 68);
}
@media (max-width: 455px) {
    .promo__container{
        height: 550px;
      
    }
}